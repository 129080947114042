import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IParameterFilterDto } from 'app/models/parameter';
import { IParameterValues } from './models';

export function createAndFillParameterControl(parameterFilter?: Partial<IParameterFilterDto>) {
    const group = new UntypedFormGroup({
        id: new UntypedFormControl(Math.round(Math.random() * -1000)),
        parameterTypeGroupId: new UntypedFormControl(null),
        parameterTypeEnumId: new UntypedFormControl(null),
        value: new UntypedFormControl(null),
        valueFrom: new UntypedFormControl(null),
        valueTo: new UntypedFormControl(null),
        cableLeftTypeEnumId: new UntypedFormControl(null),
        cableRightTypeEnumId: new UntypedFormControl(null),
        $state: new UntypedFormControl('added'),
        filterInfo: new UntypedFormGroup({
            typeGroupId: new UntypedFormControl(null),
            renderType: new UntypedFormControl(null),
            groupId: new UntypedFormControl(null),
            groupName: new UntypedFormControl(null),
            isHierarchic: new UntypedFormControl(null),
            typeId: new UntypedFormControl(null),
            typeName: new UntypedFormControl(null),
            typeType: new UntypedFormControl(null),
            typeOrder: new UntypedFormControl(null),
            typeEnumId: new UntypedFormControl(null),
            typeEnumName: new UntypedFormControl(null),
            typeEnumParentId: new UntypedFormControl(null),
            unitId: new UntypedFormControl(null),
            unitName: new UntypedFormControl(null),
            cableLeftTypeEnumId: new UntypedFormControl(null),
            cableLeftTypeEnumName: new UntypedFormControl(null),
            cableRightTypeEnumId: new UntypedFormControl(null),
            cableRightTypeEnumName: new UntypedFormControl(null)
        })
    });
    if (parameterFilter) {
        group.patchValue(parameterFilter);
    }
    return group;
}

export function getParameterValuesForModal(parametersFormArrayValues: Array<any>) {
    const parameterValues: IParameterValues = {};
    parametersFormArrayValues.forEach((controlVal) => {
        if (controlVal.$state !== 'deleted') {
            if (!parameterValues[controlVal.parameterTypeGroupId]) {
                parameterValues[controlVal.parameterTypeGroupId] = {};
            }
            if (controlVal.parameterTypeEnumId) {
                if (!parameterValues[controlVal.parameterTypeGroupId].enumIds) {
                    parameterValues[controlVal.parameterTypeGroupId].enumIds = [];
                }
                parameterValues[controlVal.parameterTypeGroupId].enumIds = [
                    ...parameterValues[controlVal.parameterTypeGroupId].enumIds,
                    {
                        enumId: controlVal.parameterTypeEnumId,
                        id: controlVal.id
                    }
                ];
            } else if (controlVal.value) {
                if (!parameterValues[controlVal.parameterTypeGroupId].values) {
                    parameterValues[controlVal.parameterTypeGroupId].values = [];
                }
                parameterValues[controlVal.parameterTypeGroupId].values = [
                    ...parameterValues[controlVal.parameterTypeGroupId].values,
                    {
                        value: controlVal.value,
                        id: controlVal.id
                    }
                ];
            } else if (controlVal.cableLeftTypeEnumId || controlVal.cableRightTypeEnumId) {
                parameterValues[controlVal.parameterTypeGroupId] = {
                    ...parameterValues[controlVal.parameterTypeGroupId],
                    cableLeft: controlVal.cableLeftTypeEnumId,
                    cableRight: controlVal.cableRightTypeEnumId,
                    id: controlVal.id
                };
            } else {
                parameterValues[controlVal.parameterTypeGroupId] = {
                    ...parameterValues[controlVal.parameterTypeGroupId],
                    valueFrom: controlVal.valueFrom,
                    valueTo: controlVal.valueTo,
                    id: controlVal.id
                };
            }
        }
    });
    return parameterValues;
}
