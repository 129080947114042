import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services';
import { FileUtils } from 'app/common/utils';
import { IIdNameDto, ILocalizationDto, ISelectListItemDto } from 'app/models';
import {
    ICategoryCompositeDto,
    ICategoryCountryExtendedDto,
    ICategoryImagesDto,
    ICategoryMainExtendedWithLockDto,
    ICategoryOverviewCollectionFilterDto,
    ICategoryParameterInfoDto,
    ICategoryParametersDto,
    ICategoryProducerDto,
    ICategoryProducerTextsDto,
    ICategoryTextsDto,
    ICategoryTopProducerExtendedDto,
    ICategoryUrlChangeFlagsDto
} from 'app/models/category';
import { CategoryCountryVisibilityChange } from 'app/models/enums';
import { ITagRelationExtendedDto } from 'app/models/tag';
import moment from 'moment';

@Injectable()
export class CategoryDataService {
    private _apiRoute = '/api/category/';

    constructor(private readonly dialogs: DialogsService, private http: HttpClient, private readonly translate: TranslateService) {}

    public categoryCountries(id: number) {
        return this.http.get<Array<ICategoryCountryExtendedDto>>(this._apiRoute + id + '/countries');
    }

    public categoryCountryVisibilityChange(id: number, languageId: number, visible: boolean) {
        return this.http.get<CategoryCountryVisibilityChange>(`${this._apiRoute}${id}/country-visibility-change?languageId=${languageId}&visible=${visible}`);
    }

    public categoryTypes() {
        return this.http.get<Array<ISelectListItemDto>>(this._apiRoute + 'types');
    }

    public categoryImages(id: number) {
        return this.http.get<ICategoryImagesDto>(this._apiRoute + id + '/images');
    }
    public categoryOverviewExport(filter: Partial<ICategoryOverviewCollectionFilterDto>) {
        const stringParams = {};
        Object.keys(filter)
            .filter((key) => filter[key] != null && filter[key] !== '' && (filter[key].length === undefined || filter[key].length > 0))
            .forEach((key) => (stringParams[key] = String(filter[key])));
        this.http
            .get('/api/category/overview-csv', {
                params: new HttpParams({ fromObject: stringParams }),
                responseType: 'arraybuffer',
                observe: 'response'
            })
            .subscribe(
                (result) => {
                    if (result.status === 204) {
                        this.dialogs.warningMessage(this.translate.instant('Common_CsvEmpty'));
                        return;
                    }
                    const fileName = `Category_Overview_${moment().format('YYYYMMDD_HHmm')}.csv`;
                    FileUtils.downloadCsvFile(fileName, result.body);
                },
                (error) => {
                    this.dialogs.badRequestMessage(error);
                }
            );
    }

    public categoryTags(id: number) {
        return this.http.get<Array<ITagRelationExtendedDto>>(this._apiRoute + id + '/tags');
    }

    public categoryTopProducers(id: number) {
        return this.http.get<Array<ICategoryTopProducerExtendedDto>>(this._apiRoute + id + '/top-producers');
    }

    public categoryActiveCountries(id: number) {
        return this.http.get<Array<IIdNameDto>>(this._apiRoute + id + '/active-countries');
    }

    public getParametersFromUrl(url: string) {
        return this.http.get<ICategoryParametersDto>(this._apiRoute + 'parse-url?url=' + encodeURIComponent(url));
    }

    public categoryProducers(id: number) {
        return this.http.get<Array<ICategoryProducerDto>>(this._apiRoute + id + '/producers');
    }

    public categoryParameters(id: number) {
        return this.http.get<ICategoryParametersDto>(this._apiRoute + id + '/parameters');
    }

    public categoryParameterInfo(id: number) {
        return this.http.get<ICategoryParameterInfoDto>(`/api/category/${id}/parameter-info`);
    }

    public categoryUrlBuild(data: ICategoryParametersDto) {
        return this.http.post<string>(this._apiRoute + 'build-url', data);
    }

    public categoryProducerTexts(categoryId: number, producerId: number) {
        return this.http.get<ICategoryProducerTextsDto>(this._apiRoute + categoryId + '/producer/' + producerId + '/texts');
    }

    public categoryTexts(id: number) {
        return this.http.get<ICategoryTextsDto>(this._apiRoute + id + '/texts');
    }

    public categoryUrl(id: number) {
        return this.http.get<ILocalizationDto>(this._apiRoute + id + '/url');
    }

    public categoryUrlChangeFlags(id: number) {
        return this.http.get<ICategoryUrlChangeFlagsDto>(this._apiRoute + id + '/url-change-flags');
    }

    public categoryUnassign(userTaskId: number) {
        return this.http.delete(`/api/user-task/${userTaskId}`);
    }

    public categorySave(data: ICategoryCompositeDto) {
        return this.http.patch(this._apiRoute, data);
    }

    public loadMain(id: number) {
        return this.http.get<ICategoryMainExtendedWithLockDto>(`/api/category/${id}`);
    }
}
